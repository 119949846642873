import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorScheme } from '@core/enums/color-scheme';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-default-button',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './default-button.component.html',
})
export class DefaultButtonComponent {
  ColorScheme = ColorScheme;
  @Input() stretch = false;
  @Input() loading = false;
  @Input() type: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() disabled = false;

  @Output() clickEvent = new EventEmitter<void>();

  buttonClicked(): void {
    this.clickEvent.emit();
  }
}
